import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { TransitionMixin, media } from '../helpers'
import Layout from '../components/layout'
import SEO from '../components/seo'

const BannerWrap = styled.div`
  position: relative;
  padding-top: 60px;
  ${media.small`padding-top: 0px;`};
  .gatsby-image-wrapper {
    overflow: hidden;
    max-height: 75vh;
  }

  .overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(42, 42, 42, 0.4);
  }

  .caption {
    position: absolute;
    top: 20vh;
    width: 100%;
    text-align: center;
    ${media.small`padding-top: 0px;`};
    ${media.medium`top: 32vh;`};

    h1 {
      color: #fff;
      max-width: 700px;
      margin: 0 auto;
      line-height: 50px;
      font-size: 1.75rem;

      ${media.small`font-size: 2.25rem;`};

      span {
        display: block;
        font-size: 17px;
        font-weight: 300;
        display: none;
        line-height: 25px;
        display: none;
        max-width: 450px;
        margin: 10px auto;
        ${media.small`display: block;`};
      }
    }
  }

  .btn-wrap {
    width: 100%;
    text-align: center;
    margin-top: 20px;

    .btn-clear {
      color: #fff;
      text-decoration: none;
      display: inline-block;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 1.5px;
      border: 1px solid #fff;
      padding: 10px 20px;

      border-radius: 4px;
      ${TransitionMixin('.25s')};

      &:first-child {
        margin-right: 20px;
      }

      ${media.small`width: 200px;padding: 10px 0px;`};

      &:hover,
      &:active,
      &:focus {
        background-color: #fff;
        color: #2a2a2a;
      }
    }
  }
`

const LogoGrid = styled.section`
  .title-wrap {
    padding: 40px 0px 40px;
    text-align: center;
    h4 {
      font-size: 21px;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 2.5px;
      margin-bottom: 0px;
    }
  }
  .wrapper {
    max-width: 70vw;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    .logo {
      flex: 1 1 50%;
      text-align: center;
      align-self: center;
      ${media.small`flex: 1;`};
      .gatsby-image-wrapper {
        overflow: hidden;
        max-width: 100px;
        margin: 0 auto 20px;

        ${media.small`max-width: 150px; margin: 0 auto;`};

        img {
          margin-bottom: 0px;
        }
      }
    }
  }
`

const AboutWrap = styled.section`
  padding: 100px 0px;
  margin: -40px;
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 90vw;
    margin: 0 auto;

    ${media.small`flex-wrap: nowrap;`};

    .image-wrap {
      flex: 1 1 100%;

      margin: 0 auto 40px;
      ${media.small`flex: 1; margin-right: 40px;`};

      .gatsby-image-wrapper {
        max-width: 450px;
        max-height: 300px;
        margin: 0 auto;
        ${media.small`flex: 1;max-height: 100%; `};
      }
    }
    .content-wrap {
      flex: 1;
      align-self: center;
    }
  }
`

const IndexPage = ({ data, location }) => {
  console.log('index', data)
  const { banner, headshot } = data
  const { acf } = data.wordpressPage
  const logo_grid = acf.logo_grid.map(logo => logo)
  console.log(acf)

  function createMarkup() {
    return { __html: data.wordpressPage.acf.about_content }
  }

  return (
    <Layout location={location}>
      <SEO title="Home" description="I’m a sports journalist from San Francisco, currently studying at the Walter Cronkite School of Journalism and Mass Communication at Arizona State University." keywords={[`journalism`, `sports`, `interview`]} />
      <BannerWrap>
        {acf.banner_image ? (
          <Img fluid={acf.banner_image.localFile.childImageSharp.fluid} />
        ) : (
          <Img fluid={banner.childImageSharp.fluid} />
        )}
        <div className="overlay" />
        <div className="caption">
          <h1>
            {acf.banner_title ? acf.banner_title : ''}

            <span>{acf.banner_caption ? acf.banner_caption : ''}</span>
          </h1>
          <div className="btn-wrap">
            <Link to="#about" className="btn-clear">
              About Me
            </Link>
            <Link to="/work" className="btn-clear">
              View Work
            </Link>
          </div>
        </div>
      </BannerWrap>

      <LogoGrid>
        <div className="title-wrap">
          <h4>Previous clients include:</h4>
        </div>
        <div className="wrapper">
          {logo_grid.map(item => {
            let logo = item.logo
            return (
              <div className="logo">
                {logo.localFile ? (
                  <Img fluid={logo.localFile.childImageSharp.fluid} />
                ) : (
                  ''
                )}
              </div>
            )
          })}
        </div>
      </LogoGrid>

      <AboutWrap id="about">
        <div className="wrapper">
          <div className="image-wrap">
            <Img fluid={headshot.childImageSharp.fluid} />
          </div>
          <div className="content-wrap">
            <h2>{acf.about_title}</h2>
            <div
              className="content-wrap"
              dangerouslySetInnerHTML={createMarkup()}
            />
          </div>
        </div>
      </AboutWrap>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "banner.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headshot: file(relativePath: { eq: "headshot.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    wordpressPage(title: { eq: "Home" }) {
      title
      id
      acf {
        banner_title
        banner_caption
        about_title
        about_content
        banner_image {
          localFile {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        logo_grid {
          logo {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
